export const reducer = (state, action) => {
  switch (action.type) {
    case "updateFirebaseData":
      return {
        ...state,
        firebaseData: action.value,
      };

    case "updateStreamingData":
      return {
        ...state,
        streamingData: action.value,
      };

    case "updateServiceData":
      return {
        ...state,
        serviceData: action.value,
      };
    case "selectRegion":
      return {
        ...state,
        selectedRegion: action.value,
      };
    case "updateSourceGroup":
      return {
        ...state,
        userSourceGroup: action.value,
      };
    case "updateGloballyLocked":
      return {
        ...state,
        globallyLocked: action.value,
      };
    case "updateDarkModeGlobal":
      return {
        ...state,
        darkModeGlobal: action.value,
      };
    case "updateControllerBindings":
      return {
        ...state,
        controllerBindings: action.value,
      };
    case "updateAvailableSources":
      return {
        ...state,
        availableSources: action.value,
      };

    case "toggle_button":
      return {
        ...state,
        active: !state.active,
      };
    case "toggle_else":
      return {
        ...state,
        text: action.value,
      };
    case "toggle_Multiviewer":
      return {
        ...state,
        showMultiviewerInPanel: !state.showMultiviewerInPanel,
      };
    case "toggle_RouterPanel":
      return {
        ...state,
        showRouterPanel: !state.showRouterPanel,
      };
    case "toggle_SettingsPanel":
      return {
        ...state,
        showSettingsPanel: !state.showSettingsPanel,
      };

    case "toggle_Take":
      return {
        ...state,
        takeButtonEnabled: !state.takeButtonEnabled,
        currentSelecDest: {},
        currentSelecSourc: {},
      };
    case "toggle_renameDestinationsEnabled":
      return {
        ...state,
        renameDestinationsEnabled: !state.renameDestinationsEnabled,
      };
    case "toggle_Controller":
      return {
        ...state,
        controllerEnabled: !state.controllerEnabled,
      };
    case "toggle_ControllerSettings":
      return {
        ...state,
        controllerSettingsEnabled: !state.controllerSettingsEnabled,
      };
    case "toggle_darkMode":
      return {
        ...state,
        darkModeEnabled: !state.darkModeEnabled,
      };
    case "changeCurrentColorMode":
      return {
        ...state,
        darkModeEnabled: action.value,
      };
    case "lockRouterPanel":
      return {
        ...state,
        panelLocked: !state.panelLocked,
      };
    case "unselectCurrentSelection":
      return {
        ...state,
        currentSelecDest: {},
        currentSelecSourc: {},
      };
    case "unselectSource":
      return {
        ...state,
        currentSelecSourc: {},
      };
    case "selectSource":
      return {
        ...state,
        currentSelecSourc: action.value,
      };
    case "selectDestination":
      return {
        ...state,
        currentSelecDest: state.firebaseData.Destinations[action.value2],
      };
    case "updateCurSelDestination":
      return {
        ...state,
        currentSelecDest: state.firebaseData.Destinations[action.value2],
      };
    case "logging":
      return {
        ...state,
        logging: action.value,
      };
    case "loading":
      return {
        ...state,
        loading: action.value,
      };
    case "waitingOnRouteManager":
      return {
        ...state,
        loading: action.value,
      };
    case "setMessage":
      return {
        ...state,
        lastMessage: action.value,
      };
    case "initControllerBindings":
      return {
        ...state,
        controllerInit: true,
        controllerBindings: action.value,
      };
    case "takeRequest":
      return {
        ...state,
        takeRequestPresent: action.value,
      };
    case "toggle_ErrorSnackbar":
      return {
        ...state,
        errorSnackOpen: action.value,
      };

    case "set_ErrorSnackbarMessage":
      return {
        ...state,
        errorSnackMessage: action.value,
      };
    case "updateRoutingManagerPort":
      return {
        ...state,
        routingManagerPort: action.value,
      };

    case "updateRoutingManagerAddress":
      return {
        ...state,
        routingManagerAddress: action.value,
      };

    case "updateRoutingManagerAPIKey":
      return {
        ...state,
        routingManagerApiKey: action.value,
      };
    case "updateRoutingManagerTenantId":
      return {
        ...state,
        routingManagerTenantId: action.value,
      };
    case "updateRoutingManagerType":
      return {
        ...state,
        routingManagerType: action.value,
      };
    case "addControllerBinding":
      return {
        ...state,
        controllerBindings: action.value,
      };
    case "disableController":
      return {
        ...state,
        controllerEnabled: false,
      };

    case "disableControllerSettings":
      return {
        ...state,
        controllerSettingsEnabled: false,
      };
    case "enableDarkmode":
      return {
        ...state,
        darkModeEnabled: true,
      };
    case "takeRequestPresent":
      return {
        ...state,
        takeRequestPresent: action.value,
      };

    case "changeSourceTabsIndex":
      return {
        ...state,
        sourceTabsIndex: action.value,
      };
    case "changeDestinationTabsIndex":
      return {
        ...state,
        destinationTabsIndex: action.value,
      };
    case "changeMultiviewerStream":
      return {
        ...state,
        multiviewerStream: action.value,
      };

    case "addDistributions":
      return {
        ...state,
        distributions: action.value,
      };

    case "addBranding":
      return {
        ...state,
        branding: action.value,
      };
    default:
      return state;
  }
};

export const initialState = {
  //firebaseData = Userdata
  firebaseData: { Destinations: [] },
  distributions: [],
  userSourceGroup: "undefined",
  globallyLocked: false,
  availableSources: [],
  takeRequestPresent: false,
  errorSnackOpen: false,
  errorSnackMessage: "Error",
  lastMessage: "",
  serverOnline: true,
  logging: false,
  alertedOnServerOffline: false,
  panelLocked: false,
  showMultiviewerInPanel: false,
  showRouterPanel: true,
  showSettingsPanel: false,
  takeRequestPresent: false,
  loading: false,
  controllerInit: false,
  controllerEnabled: false,
  controllerSettingsEnabled: false,
  renameDestinationsEnabled: false,
  darkModeEnabled: null,
  takeButtonEnabled: true,
  currentSelecDest: {},
  currentSelecSourc: {},
  controllerBindings: [],
  darkModeGlobal: null,
  selectedRegion: "EU",
  streamingData: { enableMultiviewer: false },
  routingManagerAddress: null,
  routingManagerPort: null,
  routingManagerApiKey: "",
  routingManagerTenantId: "",
  routingManagerType: "",
  destinationTabsIndex: 0,
  sourceTabsIndex: 0,
  multiviewerStream: "MatchA",
  serviceData: {},
  branding: {
    logoURL: "",
    logoDarkURL: "",
    backgroundURL: "",
  },
};
