import React from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from "../contexts/UserProvider";
import { Card, CardContent } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import SourceTabs from "./SourceTabPanel";
import RouterPanelMV from "./RouterPanelMV";
import { getNewLightTheme, getNewDarkTheme } from "./Theme";
import VpnLockIcon from "@mui/icons-material/VpnLock";
import PublicOffIcon from "@mui/icons-material/PublicOff";
import Undo from "@mui/icons-material/Undo";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DestinationTabPanel from "./DestinationTabPanel";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import VideogameAssetOffIcon from "@mui/icons-material/VideogameAssetOff";
import { useState, useEffect, useRef } from "react";
import { colors } from "./Theme";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import UploadIcon from "@mui/icons-material/Upload";
function RouterPanel(props) {
  const [state, dispatch] = React.useContext(UserContext);
  const [isMobile, setIsMobile] = React.useState(false);
  const useStyles = makeStyles(
    state.darkModeEnabled ? getNewDarkTheme(colors) : getNewLightTheme(colors)
  );
  const classes = useStyles();

  const checkIfMobile = () => {
    const viewportWidth = window.innerWidth;
    setIsMobile(viewportWidth < 768); // You can adjust the threshold as needed
  };

  React.useEffect(() => {
    checkIfMobile();

    // Event listener to check when the viewport size changes
    window.addEventListener("resize", checkIfMobile);

    // Cleanup function to remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  function takeOnSelect(source) {
    dispatch({ type: "selectSource", value: source });
    props.take(source.SourceName, source.SourceNumber, source.Format);
  }

  function handleNewSourceGroup(sourceGroupNumber) {
    let streamChannel = "MatchA";

    if (sourceGroupNumber === 0) {
      streamChannel = "MatchA";
      dispatch({ type: "changeMultiviewerStream", value: streamChannel });
    } else if (sourceGroupNumber === 1) {
      streamChannel = "MatchB";
      dispatch({ type: "changeMultiviewerStream", value: streamChannel });
    } else if (sourceGroupNumber === 2) {
      streamChannel = "MatchC";
      dispatch({ type: "changeMultiviewerStream", value: streamChannel });
    }
  }

  return (
    <div>
      {state.showMultiviewerInPanel ? (
        <RouterPanelMV sourceGroupForStream={state.multiviewerStream} />
      ) : null}
      <Card className={classes.backgroundArea} style={{ borderRadius: "0px" }}>
        <CardContent>
          <Box display="flex" justifyContent="center">
            <Button
              className={
                state.panelLocked || state.globallyLocked
                  ? classes.lockButtonWhileLocked
                  : classes.lockButton
              }
              disabled={state.globallyLocked}
              variant="contained"
              onClick={() => dispatch({ type: "lockRouterPanel" })}
              startIcon={
                state.panelLocked ? (
                  <LockIcon
                    sx={{
                      transform: "scale(1.5)",
                    }}
                  />
                ) : (
                  <LockOpenIcon
                    sx={{
                      transform: "scale(1.5)",
                    }}
                  />
                )
              }
            >
              {!isMobile && (
                <Typography>
                  {state.panelLocked ? "Locked" : "Unlocked"}
                </Typography>
              )}
            </Button>
            <Button
              className={classes.lockButton}
              disabled={state.panelLocked || state.globallyLocked}
              variant="contained"
              onClick={() => dispatch({ type: "unselectCurrentSelection" })}
              startIcon={
                <Undo
                  sx={{
                    transform: "scale(1.3)",
                  }}
                />
              }
            >
              {!isMobile && <Typography>Deselect</Typography>}
            </Button>
            <Button
              className={classes.lockButton}
              disabled={false}
              variant="contained"
              onClick={() => props.changeGlobalLockStatus()}
              startIcon={
                state.globallyLocked ? (
                  <VpnLockIcon
                    sx={{
                      transform: "scale(1.3)",
                    }}
                  />
                ) : (
                  <PublicOffIcon
                    sx={{
                      transform: "scale(1.3)",
                    }}
                  />
                )
              }
            >
              {!isMobile && (
                <Typography>
                  {state.globallyLocked
                    ? "Locked Globally"
                    : "Unlocked Globally"}
                </Typography>
              )}
            </Button>
            {state.streamingData.enableMultiviewer ? (
              <Button
                className={classes.MVButton}
                disabled={state.panelLocked || state.globallyLocked}
                variant="contained"
                onClick={() => dispatch({ type: "toggle_Multiviewer" })}
                startIcon={
                  <>
                    <OndemandVideoIcon
                      sx={{
                        transform: "scale(1.3)",
                        pl: "13px",
                        pt: "2px",
                      }}
                    />
                    {state.showMultiviewerInPanel ? (
                      <KeyboardArrowUpIcon
                        sx={{
                          transform: "scale(1.5)",
                          pl: "8px",
                        }}
                      ></KeyboardArrowUpIcon>
                    ) : (
                      <KeyboardArrowDownIcon
                        sx={{
                          transform: "scale(1.5)",
                          pl: "8px",
                        }}
                      ></KeyboardArrowDownIcon>
                    )}
                  </>
                }
              ></Button>
            ) : null}
            <Button
              className={
                state.controllerEnabled
                  ? classes.ControllerButtonEnabled
                  : classes.ControllerButtonDisabled
              }
              disabled={state.panelLocked || state.globallyLocked}
              variant="contained"
              onClick={() => dispatch({ type: "toggle_Controller" })}
              startIcon={
                <Box style={{ paddingLeft: "12px" }}>
                  {state.controllerEnabled ? (
                    <VideogameAssetIcon
                      sx={{
                        transform: "scale(1.3)",
                        pt: "8px",
                      }}
                    ></VideogameAssetIcon>
                  ) : (
                    <VideogameAssetOffIcon
                      sx={{
                        transform: "scale(1.3)",
                        pt: "8px",
                      }}
                    ></VideogameAssetOffIcon>
                  )}
                </Box>
              }
            ></Button>
          </Box>
        </CardContent>
      </Card>

      <Card
        className={classes.backgroundArea}
        style={{
          borderRadius: "0px",
        }}
      >
        <CardContent>
          <DestinationTabPanel
            destinationLockTrigger={props.destinationLockTrigger}
          ></DestinationTabPanel>
        </CardContent>
      </Card>

      <Card className={classes.backgroundArea} style={{ borderRadius: "0px" }}>
        <CardContent>
          <SourceTabs
            takeOnSelect={takeOnSelect}
            handleNewSourceGroup={handleNewSourceGroup}
          ></SourceTabs>
        </CardContent>
      </Card>

      <Card className={classes.backgroundArea} style={{ borderRadius: "0px" }}>
        <CardContent>
          {state.takeButtonEnabled ? (
            <Box pl={10} pr={10} pb={4} display="flex" justifyContent="center">
              <Button
                className={classes.takeButton}
                disabled={
                  !state.takeButtonEnabled ||
                  state.panelLocked ||
                  state.globallyLocked ||
                  state.loading
                }
                variant="contained"
                onClick={() =>
                  props.take(
                    state.currentSelecSourc.SourceName,
                    state.currentSelecSourc.SourceNumber,
                    state.currentSelecSourc.Format
                  )
                }
              >
                <Box
                  display="flex"
                  justifyContent={"left"}
                  alignItems={"center"}
                >
                  <Typography>TAKE</Typography>
                  {state.loading ? (
                    <HourglassEmptyIcon
                      style={{ marginLeft: "10px" }}
                      className="rotating-icon"
                    ></HourglassEmptyIcon>
                  ) : (
                    <UploadIcon style={{ marginLeft: "10px" }}></UploadIcon>
                  )}
                </Box>
              </Button>
            </Box>
          ) : (
            <Box display="flex" justifyContent={"center"} alignItems={"center"}>
              {state.loading ? (
                <HourglassEmptyIcon
                  style={{ height: "50px" }}
                  className="rotating-icon"
                ></HourglassEmptyIcon>
              ) : (
                <Box style={{ height: "50px" }}></Box>
              )}
            </Box>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default RouterPanel;
