import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { UserContext } from "../contexts/UserProvider";
import { colors, otherStyling } from "./Theme";
import { Typography, Box } from "@material-ui/core";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ErrorSnackbar() {
  const [state, dispatch] = React.useContext(UserContext);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: "toggle_ErrorSnackbar", value: false });
  };

  return (
    <div>
      <Snackbar
        open={state.errorSnackOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Alert
            onClose={handleClose}
            severity="error"
            sx={{
              width: "100%",
              backgroundColor: colors.danger1,
              borderRadius: otherStyling.borderRadius,
              display: "flex",
              alignItems: "center", // Align content inside the Alert
            }}
          >
            <Typography sx={{ margin: 0, textAlign: "center", width: "100%" }}>
              {state.errorSnackMessage}
            </Typography>
          </Alert>
        </Box>
      </Snackbar>
    </div>
  );
}
