import React from "react";
import { UserContext } from "../contexts/UserProvider";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { colors } from "./Theme";
import RegionSelector from "./RegionSelector";
import ControllerSettingsTable from "./ControllerSettingsTable";
import DestinationRenamer from "./DestinationRenamer";

function SettingsPanel(props) {
  const [state, dispatch] = React.useContext(UserContext);

  const useStyles = makeStyles({
    settingsCard: {
      width: "100%",
      margin: 8,
      color: state.darkModeEnabled ? colors.lightColor1 : colors.darkColor1,
      backgroundColor: state.darkModeEnabled
        ? colors.darkColor1
        : colors.lightColor1,
    },

    headline: {
      justifyContent: "center",
      display: "flex",
      fontSize: "17px",
      padding: 15,
      color: state.darkModeEnabled ? colors.lightColor1 : colors.darkColor1,
    },
    controlLabels: {
      fontSize: "14px",
      paddingTop: "4px",
    },
  });
  const classes = useStyles();

  function updateGlobalDarkMode() {
    props.updateGlobalDarkMode();
  }

  function updateStandardRegion() {
    props.updateStandardRegion();
  }

  return (
    <div>
      <Card
        className={classes.headline}
        style={{ opacity: "95%", borderRadius: "0px", paddingBottom: "45vh" }}
      >
        <CardContent>
          {state.streamingData.enableMultiviewer ? (
            <Box>
              <RegionSelector
                updateStandardRegion={updateStandardRegion}
              ></RegionSelector>
            </Box>
          ) : null}
          <Box style={{ marginBottom: "30px" }}>
            <DestinationRenamer></DestinationRenamer>
          </Box>
          <Box display="flex" justifyContent="left">
            <FormGroup>
              <FormControlLabel
                label={
                  <Typography className={classes.controlLabels}>
                    Use & show take button
                  </Typography>
                }
                control={
                  <Switch
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    checked={state.takeButtonEnabled}
                    onChange={() => dispatch({ type: "toggle_Take" })}
                  />
                }
              />
            </FormGroup>
          </Box>
          <Box display="flex" justifyContent="left">
            <FormGroup>
              <FormControlLabel
                label={
                  <Typography className={classes.controlLabels}>
                    Enable dark mode locally
                  </Typography>
                }
                control={
                  <Switch
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    checked={state.darkModeEnabled}
                    onChange={() => dispatch({ type: "toggle_darkMode" })}
                  />
                }
              />
            </FormGroup>
          </Box>
          <Box display="flex" justifyContent="left">
            <FormGroup>
              <FormControlLabel
                label={
                  <Typography className={classes.controlLabels}>
                    Make dark mode standard theme for this account
                  </Typography>
                }
                control={
                  <Switch
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    checked={state.darkModeGlobal}
                    onChange={() => {
                      updateGlobalDarkMode();
                    }}
                  />
                }
              />
            </FormGroup>
          </Box>
          <Box display="flex" justifyContent="left">
            <FormGroup>
              <FormControlLabel
                label={
                  <Typography className={classes.controlLabels}>
                    Enable controller
                  </Typography>
                }
                control={
                  <Switch
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    checked={state.controllerEnabled}
                    onChange={() => {
                      dispatch({ type: "toggle_Controller" });
                      dispatch({ type: "disableControllerSettings" });
                    }}
                  />
                }
              />
            </FormGroup>
          </Box>
          <Box display="flex" justifyContent="left">
            <FormGroup>
              <FormControlLabel
                label={
                  <Typography className={classes.controlLabels}>
                    Edit controller settings
                  </Typography>
                }
                control={
                  <Switch
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    checked={state.controllerSettingsEnabled}
                    onChange={() =>
                      dispatch({ type: "toggle_ControllerSettings" })
                    }
                  />
                }
              />
            </FormGroup>
          </Box>
          {state.controllerSettingsEnabled ? (
            <ControllerSettingsTable></ControllerSettingsTable>
          ) : null}
        </CardContent>
      </Card>
    </div>
  );
}

export default SettingsPanel;
